.icon-text{
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 980px) {
    .header-navigation img, #app-navigation img {
        height: 50px !important;
    }
}

.header{
    display: flex;
    justify-content: center;
    background-color: white;
    box-shadow: rgb(163 148 148 / 24%) 0 6px 3px -3px;
    gap: 10rem;
    align-items: center;
}

@media screen and (max-width: 980px) {
    .header {
        gap: 0 !important;
    }
}

@media screen and (max-width: 500px) {
    #socialmedia-div img {
        height: 35px;
    }
}

/* Navigation container */
.navigation-container {
    position: relative;
}

/* Default: Hide menu button on large screens */
.menu-button {
    display: none;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

/* Navigation links (default: always visible) */
.header-navigation {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
}

.header-navigation a {
    display: flex;
    align-items: center;
    text-decoration: none;
    flex-direction: column;
    color: black;
}


/* Small Screens: Enable Collapsible Menu */
@media (max-width: 715px) {
    /* Show menu button */
    .menu-button {
        display: flex;
    }

    /* Initially hide the menu */
    .header-navigation {
        display: none;
        flex-direction: column;
        position: absolute;
        right: 0;
        background: white;
        border: 1px solid #ddd;
        padding: 10px;
        z-index: 9;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }

    /* Show menu when "open" class is added */
    .header-navigation.open {
        display: flex;
        align-items: flex-start;
    }

    .header-navigation .icon-text{
        flex-direction: row;
    }

    .header-navigation img{
        height: 40px !important;
        margin-right: 0.5rem;
    }

    .header-navigation a {
        flex-direction: row;
    }

    .header-navigation p {
        font-size: .8rem !important;
        color: black;
    }
}
