.logo-footer{
    display: flex;
    gap: 4rem;
    justify-content: flex-end;
    padding-right: 6rem;
    padding-bottom: 2rem;
}

@media screen and (max-width: 715px){
    .logo-footer {
        justify-content: center;
        padding-right: 0;
        flex-wrap: wrap;
        row-gap: 2rem;
    }
}