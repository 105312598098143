:root {
    --salmon: #f49c88;
    --blueish: #46afbf;
    --whitish: #f9f4f4
}

html, body, #root, #mainContainer {
    margin: 0;
    height: 100%;
    font-family: "Roboto Bold", sans-serif;
}

#mainContainer {
    margin: 0;
    padding: 0;
    max-width: 100%;
}

/* First row on every page, mostly the heading of the page */
.firstRow{
    margin-bottom: 1rem;
    padding-top: 1rem;
}

.heading {
    color: var(--blueish)
}

.horizontallyCentered{
    text-align: center;
}

.filter-label{
    margin-top: .5rem;
}

/* Static content div */
.content{
    display: flex;
    justify-content: center;
}

/* App content */
.app-content-container{
    margin-top: 2rem;
}

.page-image-div{
    display: flex;
    justify-content: center;
    z-index: -1;
    margin-top: -100px;
    min-height: 300px;
}

.page-image{
    z-index: inherit;
    max-width: 100%;
}

/* Texts of static pages */
.text-block{
    margin-top: 1rem;
    text-align: justify;
}

.text-block > h5 {
    font-size: 1.1rem;
    font-weight: bold;
    color: var(--blueish);
}

@media screen and (max-width: 980px) {
    .main-navigation img {
        height: 120px;
    }

    .main-navigation {
        justify-content: center;
        margin-top: -2rem;
        gap: 2rem !important;
    }

    .main-navigation .icon-text {
        flex-basis: 30%; /* Makes sure two items are in one row */
    }
}

@media screen and (max-width: 500px) {
    .main-navigation img {
        height: 100px;
    }

    .main-navigation p {
        font-size: 1rem !important;
    }

    .page-image-div{
        min-height: 240px;
        margin-top: -80px;
    }
}

@media screen and (max-width: 715px){
    .content:not(.homePage .content) {
        padding: 1rem !important;
    }

    #teamimage-col > img{
        width: 100%;
    }
}

/* Topic modal */
.topicSelectModal section{
    overflow-y: visible;
}