.newPlanButtonRow {
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;
    padding-bottom: 1.5rem;
}

.newPlanButtonRow > .btn {
    margin-right: 24px;
}

.newExerciseField {
    margin-bottom: 1rem;
}

.newExerciseField > label{
    font-weight: bold;
}

.newExerciseField .mantine-InputWrapper-label{
    font-weight: bold;
    margin-bottom: .5rem;
    font-size: 1rem;
}

#newExercisesTable  td {
    vertical-align: middle;
}