#bigLogo > #logo {
    height: 200%;
    margin-top: 4rem;
}

#bigLogo > #text {
    height: 70%;
}

#smallLogo > #logo {
    height: 100%;
    margin-top: 0;
}

#logoContainer > div{
    display: flex;
    height: 100px;
    align-items: center;
}

#bigLogo {
    display: none !important;
}

@media screen and (min-width: 1200px) {
    #smallLogo {
        display: none !important;
    }

    #bigLogo {
        display: flex !important;
    }
}

@media screen and (max-width: 500px) {
    #logoContainer > div {
        height: 80px;
    }
}